table.seedNodes {
	font-size: 1.5vmin;
	border: 2px;
	border-collapse: collapse;
}

table.seedNodes thead th {
	padding-bottom: 1em;
}

th.block {

}