td.seen, td.unseen {
	border-top: 5px solid black;
	border-bottom: 5px solid black;
}

td.seen {
	background-color: rgb(0, 34, 2);
}

td.unseen {
	background-color: rgb(41, 0, 0);
}

td.indicatorColumn {
	width: 2em;
}

.indicator {
	width: 1em;
	height: 1em;
	border-radius: 50%;
	display: inline-block;
	background-color: gray;
}

.indicator.pulsating {
	display: block;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  .indicator.healthy {
	background-color: green;
	-webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
			animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }

  /* ring */
  .indicator.pulsating:after {
	content: "";
	position: relative;
	display: block;
	width: 300%;
	height: 300%;
	box-sizing: border-box;
	margin-left: -100%;
	margin-top: -200%;
	border-radius: 50%;
  }

  .indicator.healthy:after {
	background-color: #aaffa2;
	-webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
			animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  @-webkit-keyframes pulse-ring {
	0% {
	  transform: scale(0.33);
	}
	80%, 100% {
	  opacity: 0;
	}
  }
   
  @keyframes pulse-ring {
	0% {
	  transform: scale(0.33);
	}
	80%, 100% {
	  opacity: 0;
	}
  }
  
  @-webkit-keyframes pulse-dot {
	0% {
	  transform: scale(0.8);
	}
	50% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0.8);
	}
  }
  @keyframes pulse-dot {
	0% {
	  transform: scale(0.8);
	}
	50% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0.8);
	}
  }