body {
	margin: 0;
	padding: 20px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-size: calc(10px + 1vmin);
	margin: 2vmin 0 0 0;
	text-align: left;

	background-color: black;
	color: white;
}

#body-wrapper {
	margin: auto;
	width: 800px;
}

@media only screen and (max-width: 800px) {
	#body-wrapper {
		width: 100%;
	}
}

pre {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	white-space: pre;
	font-size: 1.2vmin;
	line-height: 1.2em;
	background-color: rgb(26, 26, 26);
	padding: 1em 1em;
}

a {
	color: white;
}

a:visited {
	color: white;
}

h2 {
	margin-top: 2em;
}

input[type=text], select, textarea {
	padding: 12px;
	border: 1px solid rgb(44, 44, 44);
	border-radius: 4px;
	background-color: rgb(27, 27, 27);
	color: white;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

input[type=text]:focus, select:focus, textarea:focus {
	border: 1px solid rgb(77, 77, 77);
}

button, input[type=submit] {
	background-color: rgb(27, 27, 27);
	color: white;
	padding: 12px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

button:hover, input[type=submit]:hover {
	background-color: rgb(0, 46, 3);
}

::-webkit-scrollbar {
	width: 0.2em;
}
   
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
} 

pre.ascii {
	display: inline-block;
	font-size: 1.2vw;
	color: rgb(18, 211, 0);
	background-color: transparent;
	margin-left: auto;
	margin-right: auto;
	overflow: none;
	position: relative;
	left: -1vw;
}

pre.ascii.ascii-animation {
    background: linear-gradient(90deg, rgb(180, 180, 180) 0%, rgb(255, 255, 255) 17%, rgb(180, 180, 180) 35%, rgb(180, 180, 180) 100%);
    background-size: 400% 400%;

    -webkit-animation: ascii-animation 5s ease infinite;
    -moz-animation: ascii-animation 5s ease infinite;
    animation: ascii-animation 5s ease infinite;
	animation-direction: normal;
	
    -webkit-background-clip: text;
    -moz-background-clip: text;
	background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
	margin: 0;
}

@-webkit-keyframes ascii-animation {
    0%{background-position:100% 50%}
    100%{background-position:-50% 50%}
}
@-moz-keyframes ascii-animation {
    0%{background-position:100% 50%}
    100%{background-position:-50% 50%}
}
@keyframes ascii-animation {
    0%{background-position:100% 50%}
    100%{background-position:-50% 50%}
}