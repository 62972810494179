.eventItem {
    display: block;
    list-style: none;
    margin: 0 1% 1vmin 0;
	--default-pad: 1vmin;
    padding: var(--default-pad);

	font-size: 2vmin;
	position: relative;
	text-align: left;

	width: 100%;
	overflow: hidden;

	border-radius: 1vmin;
}

.eventItem img.externalLinkIcon {
	height: 1.5vmin;
}

.eventItem:not(:hover) img.externalLinkIcon {
	display: none;
}

.quorumLine {
	position: absolute;
	top: 0;
	width: 0px;
	height: 100%;
	border-right: 2px dotted rgba(255, 255, 255, 0.4);
}

.accepted {
	background-color: rgb(0, 24, 2);
}

.accepted:hover {
	background-color: rgb(0, 64, 3);
}

.notAccepted {
	background-color: rgb(24, 0, 0);
}

.notAccepted:hover {
	background-color: rgb(64, 0, 0);
}

.confirmations {
	position: absolute;
	top: var(--default-pad);
	right: var(--default-pad);
}

table.args {
	margin: 1vmin 0 1vmin 0;
    padding:0;
    background:none;
    border:none;
    border-collapse:collapse;
    border-spacing:0;
    background-image:none;
	font-size: 1vmin;	
	width: 100%;
}

.eventItem:not(:hover) table.args {
	display: none;
}